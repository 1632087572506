import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import AuthService from "../../services/AuthService";
import * as Yup from "yup";
import "./ForgotPassword.css";
import logo from "../../assets/img/LOGO_CVEU.png";
import { FaEnvelope } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export const ForgotPassword: React.FC<{}> = () => {
  const { t } = useTranslation();
  const [sended, setSended] = useState<boolean>(false);

  let formik = () => (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={async (values) => {
        AuthService.resetLink({
          email: values.email,
        })
          .then((x) => {
            setSended(true);
          })
          .catch((e) => {
            console.error(e);
          });
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("Email_is_invalid"))
          .required(t("Email_is_required")),
      })}
      render={({ errors, status, touched }) => (
        <Form>
          <div className="input-group mb-3">
            <Field
              type="email"
              className="form-control"
              placeholder="Email"
              id="email"
              name="email"
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <FaEnvelope></FaEnvelope>
              </div>
            </div>

            <ErrorMessage
              name="email"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className="btn-group">
            <button type="submit" className="btn btn-primary btn-block">
              {t("Reset_password")}
            </button>
          </div>
        </Form>
      )}
    />
  );

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "black" }}>
      <video autoPlay muted loop id="myVideo">
        <source
          src="https://www.cveu.eu/wp-content/uploads/2022/05/video_reduced_cut.mp4"
          type="video/mp4"
        ></source>
      </video>
      <div className="reset-page" style={{ minHeight: "466px" }}>
        <div className="reset-box">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <img alt="logo" src={logo} style={{ maxHeight: "70px" }} />
            </div>
            <div className="card-body">
              <p className="reset-box-msg">{t("Forgot_password")}</p>
              {!sended ? (
                formik()
              ) : (
                <div className="reset-link-sended">
                  {t("Reset_link_sended")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
