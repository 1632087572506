import React, { useState } from "react";

import { Box } from "../../components/Box";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DepositsTableComponent } from "./components/DepositsTableComponent";
import { WithdrawalsTableComponent } from "./components/WithdrawalsTableComponent";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FaLock } from "react-icons/fa";
import AuthService from "../../services/AuthService";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { DetailTableComponent } from "./components/DetailTableComponent";

export const Profile: React.FC<{}> = () => {
  const [depositsTableLoaded, setDepositsTableLoaded] = useState<any>(false);
  const [withdrawalsTableLoaded, setWithdrawalsTableLoaded] =
    useState<any>(false);
  const [detailTableLoaded, setDetailTableLoaded] = useState<any>(false);
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <Row>
            <Col md={4}>
              <Box
                loaded={detailTableLoaded}
                title={
                  <div>
                    <i className="fas fa-table mr-1" />
                    {t("User_detail")}
                  </div>
                }
                isTable={true}
              >
                <div className="box-overflow" style={{ minHeight: "180px" }}>
                  <DetailTableComponent
                    onlyFirstTimeShowLoading={true}
                    loaded={(state: boolean) => {
                      setDetailTableLoaded(state);
                    }}
                  />
                </div>
              </Box>
            </Col>
            <Col md={8}>
              <Box loaded={true} title={t("Change_password")} isTable={false}>
                <Formik
                  initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    passwordConfirmation: "",
                  }}
                  onSubmit={(values) => {
                    AuthService.updatePassword({
                      newPassword: values.newPassword,
                      oldPassword: values.oldPassword,
                    })
                      .then((x) => {
                        navigate("/login");
                      })
                      .catch((e) => {
                        if (e?.response?.data?.error) {
                          setErrorMessage(e?.response?.data?.error);
                        } else {
                          console.error(e);
                        }
                      });
                  }}
                  validationSchema={Yup.object().shape({
                    oldPassword: Yup.string().required(
                      t("Old_password_is_required")
                    ),
                    newPassword: Yup.string().required(
                      t("New_password_is_required")
                    ),
                    passwordConfirmation: Yup.string().oneOf(
                      [Yup.ref("newPassword"), null],
                      t("Passwords_must_match")
                    ),
                  })}
                  render={({ errors, status, touched }) => (
                    <Form>
                      <div className="input-group mb-3">
                        <Field
                          type="password"
                          className="form-control"
                          placeholder={t("Old_password")}
                          id="oldPassword"
                          name="oldPassword"
                        />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <FaLock></FaLock>
                          </div>
                        </div>
                        <ErrorMessage
                          name="oldPassword"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="input-group mb-3">
                        <Field
                          type="password"
                          className="form-control"
                          placeholder={t("New_password")}
                          id="newPassword"
                          name="newPassword"
                        />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <FaLock></FaLock>
                          </div>
                        </div>
                        <ErrorMessage
                          name="newPassword"
                          component="div"
                          className="invalid-feedback"
                        />
                        {errorMessage ? (
                          <div className="invalid-feedback">
                            {t(errorMessage)}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="input-group mb-3">
                        <Field
                          type="password"
                          className="form-control"
                          placeholder={t("Confirm_password")}
                          id="passwordConfirmation"
                          name="passwordConfirmation"
                        />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <FaLock></FaLock>
                          </div>
                        </div>
                        <ErrorMessage
                          name="passwordConfirmation"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="btn-group">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          {t("Change_password")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </Box>
              <Box
                loaded={depositsTableLoaded}
                title={
                  <div>
                    <i className="fas fa-table mr-1" />
                    {t("Deposits")}
                  </div>
                }
                isTable={true}
              >
                <div className="box-overflow" style={{ minHeight: "180px" }}>
                  <DepositsTableComponent
                    onlyFirstTimeShowLoading={true}
                    loaded={(state: boolean) => {
                      setDepositsTableLoaded(state);
                    }}
                  />
                </div>
              </Box>
              <Box
                loaded={withdrawalsTableLoaded}
                title={
                  <div>
                    <i className="fas fa-table mr-1" />
                    {t("Withdrawals")}
                  </div>
                }
                isTable={true}
              >
                <div className="box-overflow" style={{ minHeight: "180px" }}>
                  <WithdrawalsTableComponent
                    onlyFirstTimeShowLoading={true}
                    loaded={(state: boolean) => {
                      setWithdrawalsTableLoaded(state);
                    }}
                  />
                </div>
              </Box>
            </Col>
          </Row>
        </div>{" "}
      </section>
    </div>
  );
};

export default Profile;
