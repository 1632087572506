import { ContactlessOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { cveuAuthApi, cveuDefaultApi, LOCALSTORAGE_USER } from "../constants";
import { UserModel } from "../models/UserModel";

export default class AuthService {
  static isInterceptorSet = false;

  static init() {
    if (!this.isInterceptorSet) {
      cveuDefaultApi.interceptors.response.use(
        (response: any) => {
          return response;
        },
        (error: any) => {
          if (error.response.status === 401 || error.response.status === 400) {
            // 401 is Unauthorized error
            // which means that this request failed
            // what we need to do is send a refresh request then resend the same request
            // that failed but with the new access point.
            // We can do this automatically using axios interceptors
            return AuthService.refreshToken()
              .then((response: any) => {
                const config = error.config;

                // Resending original request
                return new Promise((resolve, reject) => {
                  cveuDefaultApi
                    .request(config)
                    .then((response: any) => {
                      resolve(response);
                    })
                    .catch((error: any) => {
                      reject(error);
                    });
                });
              })
              .catch(async (error: any) => {
                await AuthService.logout();
                window.location.href = "/logout";
              });
          }
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
      );
    }
  }

  static getUser(): UserModel | null {
    const _data = localStorage.getItem(LOCALSTORAGE_USER);
    if (!_data) {
      return null;
    }
    return JSON.parse(_data);
  }

  static login(email: string, password: string) {
    return new Promise((resolve, reject) => {
      cveuAuthApi
        .post(
          "auth/login",
          {
            email: email,
            password: password,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(async (response) => {
          localStorage.setItem(
            LOCALSTORAGE_USER!,
            JSON.stringify(response.data)
          );
          AuthService.init();
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static resetPassword(data: { newPassword: string; resetToken: string }) {
    return new Promise((resolve, reject) => {
      cveuAuthApi!
        .post(
          "auth/reset/password",
          {
            newPassword: data.newPassword,
            resetToken: data.resetToken,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(async (response) => {
          localStorage.setItem(
            LOCALSTORAGE_USER!,
            JSON.stringify(response.data)
          );
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static resetLink(data: { email: string }) {
    return new Promise((resolve, reject) => {
      cveuAuthApi!
        .post(
          "auth/reset/link",
          {
            email: data.email,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updatePassword(data: { newPassword: string; oldPassword: string }) {
    return new Promise((resolve, reject) => {
      cveuAuthApi
        .post(
          "auth/update/password",
          {
            newPassword: data.newPassword,
            oldPassword: data.oldPassword,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(async (response) => {
          localStorage.setItem(
            LOCALSTORAGE_USER!,
            JSON.stringify(response.data)
          );
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static logout() {
    return new Promise((resolve, reject) => {
      cveuAuthApi
        .post("auth/logout", {}, {})
        .then((response: any) => {
          localStorage.removeItem(LOCALSTORAGE_USER!);
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static refreshToken() {
    return new Promise((resolve, reject) => {
      cveuAuthApi
        .post("auth/refresh", {}, {})
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
