import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./assets/locales/en/translation.json";
import translationSK from "./assets/locales/sk/translation.json";

const fallbackLng = ["en"];

const resources = {
  en: {
    translation: translationEN
  },
  sk: {
    translation: translationSK
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    fallbackLng, // fallback language is english.

    interpolation: {
      escapeValue: false // no need for react. it escapes by default
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
