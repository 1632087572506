import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { ODepositsTable } from "../../../services/DashboardService";
import { useTranslation } from "react-i18next";
import dateFormat from "dateformat";

import "./DepositsTableComponent.css";
import ProfileService from "../../../services/ProfileService";

export const DepositsTableComponent: React.FC<{
  loaded: any;
  onlyFirstTimeShowLoading: boolean;
  refreshTime?: number;
}> = ({ loaded, onlyFirstTimeShowLoading, refreshTime }) => {
  const { t } = useTranslation();
  let timeout: any = undefined;

  const [data, setData] = useState<ODepositsTable[]>([]);

  let columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "paymentDate",
        header: t("Payment_date"),
        size: 85,
      },
      {
        accessorKey: "processDate",
        header: t("Process_date"),
        size: 85,
      },
      {
        accessorKey: "mandantInvoiceNumber",
        header: t("Invoice_number"),
        size: 85,
      },
      {
        accessorKey: "amountWTax",
        header: t("Amount_w_tax"),
        size: 85,
      },
      {
        accessorKey: "amountWoTax",
        header: t("Amount_wo_tax"),
        size: 85,
      },
      {
        accessorKey: "currencyLongName",
        header: t("Currency"),
        size: 85,
      },
    ],
    [
      t("Payment_date"),
      t("Process_date"),
      t("Invoice_number"),
      t("Amount_w_tax"),
      t("Amount_wo_tax"),
      t("Currency"),
    ]
  );

  useEffect(() => {
    let firstTimeLoaded = true;
    const profileService: ProfileService = new ProfileService();
    async function getData() {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) loaded(false);

      try {
        let _data = await profileService.getDeposits();
        _data = _data.map((m: ODepositsTable) => {
          return {
            paymentDate: dateFormat(Date.parse(m.paymentDate), "dd.mm.yyyy"),
            processDate: dateFormat(Date.parse(m.processDate), "dd.mm.yyyy"),
            amountWTax: m.amountWTax,
            amountWoTax: m.amountWoTax,
            currencyLongName: m.currencyLongName,
            mandantInvoiceNumber: m.mandantInvoiceNumber,
          };
        });
        setData(_data);
      } catch (e) {
        console.error(e);
      }

      loaded(true);
      firstTimeLoaded = false;

      if (
        refreshTime !== null &&
        refreshTime !== undefined &&
        refreshTime > 0
      ) {
        timeout = setTimeout(() => getData(), refreshTime);
      }
    }

    getData();
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={data ? data : []}
        enableHiding={false}
        enableFilters={false}
        enableSorting={true}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableTopToolbar={false}
        enablePagination={true}
        enableColumnActions={false}
        enableBottomToolbar={true}
      />
    </div>
  );
};
