import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import AuthService from "../../services/AuthService";
import * as Yup from "yup";
import "./ResetPassword.css";
import logo from "../../assets/img/LOGO_CVEU.png";
import { FaLock } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ResetPassword: React.FC<{}> = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get("resetToken");

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "black" }}>
      <video autoPlay muted loop id="myVideo">
        <source
          src="https://www.cveu.eu/wp-content/uploads/2022/05/video_reduced_cut.mp4"
          type="video/mp4"
        ></source>
      </video>
      <div className="reset-page" style={{ minHeight: "466px" }}>
        <div className="reset-box">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <img alt="logo" src={logo} style={{ maxHeight: "70px" }} />
            </div>
            <div className="card-body">
              <p className="reset-box-msg">{t("Reset_password")}</p>
              <Formik
                initialValues={{
                  newPassword: "",
                  resetToken: resetToken ? resetToken : "",
                }}
                onSubmit={async (values) => {
                  AuthService.resetPassword({
                    newPassword: values.newPassword,
                    resetToken: values.resetToken,
                  })
                    .then((x) => {
                      navigate("/login");
                    })
                    .catch((e) => {
                      if (e?.response?.data?.error) {
                        setErrorMessage(e?.response?.data?.error);
                      } else {
                        console.error(e);
                      }
                    });
                }}
                validationSchema={Yup.object().shape({
                  newPassword: Yup.string().required(
                    t("New_password_is_required")
                  ),
                })}
                render={({ errors, status, touched }) => (
                  <Form>
                    <div className="input-group mb-3">
                      <Field
                        type="password"
                        className="form-control"
                        placeholder={t("New_password")}
                        id="newPassword"
                        name="newPassword"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <FaLock></FaLock>
                        </div>
                      </div>

                      <ErrorMessage
                        name="newPassword"
                        component="div"
                        className="invalid-feedback"
                      />

                      {errorMessage ? (
                        <div className="invalid-feedback">
                          {t(errorMessage)}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="btn-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        {t("Reset_password")}
                      </button>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
