import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";

export const Logout: React.FC<{}> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AuthService.logout();
    navigate("/login");
  }, []);
  return <div />;
};
