import { cveuDefaultApi } from "../constants";

export interface OInvestValue {
  totalInvestEur: number;
  totalInvestUsdt: number;
  calcDate: string;
}

export interface OActualValue {
  capitalUsdt: number;
  capitalEur: number;
}

export interface OPortfolioPieChart {
  futuresCapitalUsdt: number;
  reserveCapitalUsdt: number;
  futuresPercentage: number;
  reservePercentage: number;
}

export interface OPortfolioValueLineChart {
  portfolioLineChartDate: string;
  lcCapitalUsdt: number;
  lcCapitalEur: number;
  valueBtcUsdt: number;
  valueBtcEur: number;
}

export interface OProfitValueLineChart {
  profitLineChartDate: string;
  lcProfitEur: number;
  lcProfitUsdt: number;
  lcValueBtcUsdt: number;
  lcValueBtcEur: number;
}

export interface ODepositsTable {
  paymentDate: string;
  processDate: string;
  amountWTax: number;
  amountWoTax: number;
  currencyLongName: string;
  mandantInvoiceNumber: string;
}

export interface OProfitPercentTable {
  liveProfitPercentage: number;
  dtdProfitPercentage: number;
  wtdProfitPercentage: number;
  mtdProfitPercentage: number;
}

export interface OProfitCurrencyTable {
  liveProfitUsdt: number;
  dtdProfitUsdt: number;
  wtdProfitUsdt: number;
  mtdProfitUsdt: number;
  liveProfitEur: number;
  dtdProfitEur: number;
  wtdProfitEur: number;
  mtdProfitEur: number;
}

export default class DashboardService {
  delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  async getActualValue(): Promise<OActualValue> {
    const { data } = await cveuDefaultApi.get("actual-value-report", {
      headers: { "Content-Type": "application/json" },
    });

    return data;
  }

  async getInvestValue(): Promise<OInvestValue> {
    const { data } = await cveuDefaultApi.get("invest-report", {
      headers: { "Content-Type": "application/json" },
    });

    return data;
  }

  async getPortfolioPieChart(): Promise<OPortfolioPieChart> {
    const { data } = await cveuDefaultApi.get("portfolio-division", {
      headers: { "Content-Type": "application/json" },
    });

    return data;
  }

  async getPortfolioValueLineChart({
    from,
    to,
  }: {
    from: string | null;
    to: string | null;
  }): Promise<OPortfolioValueLineChart[]> {
    const params = new URLSearchParams();

    if (from) params.append("from", from);
    if (to) params.append("to", to);

    const { data } = await cveuDefaultApi.get("portfolio", {
      headers: { "Content-Type": "application/json" },
      params,
    });

    return data;
  }

  async getProfitValueLineChart({
    from,
    to,
  }: {
    from: string | null;
    to: string | null;
  }): Promise<OProfitValueLineChart[]> {
    const params = new URLSearchParams();

    if (from) params.append("from", from);
    if (to) params.append("to", to);

    const { data } = await cveuDefaultApi.get("profit-values", {
      headers: { "Content-Type": "application/json" },
      params,
    });

    return data;
  }

  async getProfitPercentTable(): Promise<OProfitPercentTable> {
    const { data } = await cveuDefaultApi.get("profit", {
      headers: { "Content-Type": "application/json" },
    });

    return data;
  }

  async getProfitCurrency(): Promise<OProfitCurrencyTable> {
    const { data } = await cveuDefaultApi.get("profit-usdt-eur", {
      headers: { "Content-Type": "application/json" },
    });

    return data;
  }
}
