import React from "react";
import { CurrencyEnum } from "../models/CurrencyEnum";
import { Header } from "./Header";
import { Menu } from "./Menu";

export const AdminLTE: React.FC<{
  children: React.ReactElement;
  onChangeCurrency: any;
}> = ({ children, onChangeCurrency }) => {
  return (
    <div>
      <div className="wrapper">
        <Header
          onChangeCurrency={(currencyEnum: CurrencyEnum) => {
            onChangeCurrency(currencyEnum);
          }}
        />
        <Menu></Menu>
      </div>
      {children}
    </div>
  );
};
