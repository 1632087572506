import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/LOGO.png";
import { UserModel } from "../models/UserModel";
import AuthService from "../services/AuthService";
import "./Menu.css";

export const Menu: React.FC<{}> = () => {
  const { t } = useTranslation();
  const user: UserModel | null = AuthService.getUser();
  return (
    <div>
      <aside
        className="main-sidebar sidebar-dark-primary elevation-4"
        style={{ backgroundColor: "black" }}
      >
        <a href="/" className="brand-link" style={{ textAlign: "center" }}>
          <img
            src={logo}
            alt="CVEU Logo"
            className="brand-imag"
            style={{
              opacity: ".8",
              maxHeight: "26px",
            }}
          />
          <span className="brand-text font-weight-light">CVEU</span>
        </a>
        <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
          <div className="os-resize-observer-host observed">
            <div
              className="os-resize-observer"
              style={{ left: 0, right: "auto" }}
            />
          </div>
          <div
            className="os-size-auto-observer observed"
            style={{ height: "calc(100% + 1px)", float: "left" }}
          >
            <div className="os-resize-observer" />
          </div>
          <div
            className="os-content-glue"
            style={{ margin: "0px -8px", width: 249, height: 190 }}
          />
          <div className="os-padding">
            <div
              className="os-viewport os-viewport-native-scrollbars-invisible os-viewport-native-scrollbars-overlaid"
              style={{ overflowY: "scroll" }}
            >
              <div
                className="os-content"
                style={{ padding: "0px 8px", height: "100%", width: "100%" }}
              >
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                  <div
                    className="info"
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  >
                    <a className="d-block" style={{ textAlign: "center" }}>
                      {user != null ? user.firstName + " " + user.lastName : ""}
                    </a>
                    <a className="d-block" style={{ textAlign: "center" }}>
                      {user != null ? user.referallKey : ""}
                    </a>
                  </div>
                </div>
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                  >
                    <li className="nav-item">
                      <NavLink
                        to={"/dashboard"}
                        className={(navData) => {
                          return (
                            "nav-link " + (navData.isActive ? "active" : "")
                          );
                        }}
                      >
                        <i className="nav-icon fas fa-tachometer-alt" />
                        <p>{t("Dashboard")}</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={"/profile"}
                        className={(navData) =>
                          "nav-link " + (navData.isActive ? "active" : "")
                        }
                      >
                        <i className="nav-icon fas fa-user" />
                        <p>{t("Profile")}</p>
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
                      <NavLink
                        to={"/contact-us"}
                        className={(navData) =>
                          "nav-link " + (navData.isActive ? "active" : "")
                        }
                      >
                        <i className="nav-icon far fa-envelope" />
                        <p>{t("Contact_us")}</p>
                      </NavLink>
                    </li> */}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden">
            <div className="os-scrollbar-track">
              <div
                className="os-scrollbar-handle"
                style={{ width: "100%", transform: "translate(0px, 0px)" }}
              />
            </div>
          </div>
          <div className="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden">
            <div className="os-scrollbar-track">
              <div
                className="os-scrollbar-handle"
                style={{
                  height: "18.275607%",
                  transform: "translate(0px, 0px)",
                }}
              />
            </div>
          </div>
          <div className="os-scrollbar-corner" />
        </div>
      </aside>
    </div>
  );
};
