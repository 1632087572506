import { cveuDefaultApi } from "../constants";

export interface ODepositsTable {
  paymentDate: string;
  processDate: string;
  amountWTax: number;
  amountWoTax: number;
  currencyLongName: string;
  mandantInvoiceNumber: string;
}

export interface OWithdrawalsTable {
  paymentDate: string;
  processDate: string;
  amountWTax: number;
  amountWoTax: number;
  currencyLongName: string;
  mandantInvoiceNumber: string;
}

export interface ODetailTable {
  referallKey: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  birthDate: string;
  address: string;
  city: string;
  postcode: string;
  iban: string;
  cardNumber: string;
}

export default class ProfileService {
  async getDeposits(): Promise<ODepositsTable[]> {
    const { data } = await cveuDefaultApi.get("deposits", {
      headers: { "Content-Type": "application/json" },
    });

    return data;
  }

  async getWithdrawals(): Promise<OWithdrawalsTable[]> {
    const { data } = await cveuDefaultApi.get("withdrawals", {
      headers: { "Content-Type": "application/json" },
    });

    return data;
  }

  async getDetail(): Promise<ODetailTable> {
    const { data } = await cveuDefaultApi.get("detail", {
      headers: { "Content-Type": "application/json" },
    });

    return data;
  }
}
