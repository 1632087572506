import React, { useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AdminLTE } from "./components/AdminLTE";
import logo from "./logo.svg";
import { CurrencyEnum } from "./models/CurrencyEnum";
import ContactUs from "./pages/contact-us/ContactUs";
import Dashboard from "./pages/dashboard/Dashboard";
import { Login } from "./pages/login/Login";
import { Logout } from "./pages/logout/Logout";
import Profile from "./pages/profile/Profile";
import { ResetPassword } from "./pages/reset-password/ResetPassword";
import AuthService from "./services/AuthService";
import { ForgotPassword } from "./pages/forgot-password/ForgotPassword";

function PrivateRoute({ children, ...rest }: { children: any }) {
  let location = useLocation();
  let user = AuthService.getUser();

  if (user !== null) {
    AuthService.init();
    // not logged in so redirect to login page with the return url
    return children;
  }

  // authorized so return child components
  return <Navigate to="/login" state={{ from: location }} />;
}

export const App: React.FC<{}> = () => {
  const [currency, setCurrency] = useState<CurrencyEnum>(CurrencyEnum.USDT);

  return (
    <div>
      <Routes>
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/logout"
          element={
            <PrivateRoute>
              <Logout />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <AdminLTE
                onChangeCurrency={(currencyEnum: CurrencyEnum) => {
                  setCurrency(currencyEnum);
                }}
              >
                <Dashboard currency={currency}></Dashboard>
              </AdminLTE>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <AdminLTE
                onChangeCurrency={(currencyEnum: CurrencyEnum) => {
                  setCurrency(currencyEnum);
                }}
              >
                <Profile></Profile>
              </AdminLTE>
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/contact-us"
          element={
            <PrivateRoute>
              <AdminLTE
                onChangeCurrency={(currencyEnum: CurrencyEnum) => {
                  setCurrency(currencyEnum);
                }}
              >
                <ContactUs></ContactUs>
              </AdminLTE>
            </PrivateRoute>
          }
        /> */}
        <Route
          path="*"
          element={
            <PrivateRoute>
              <Navigate to="/dashboard" />
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    </div>
  );
};
