import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import DashboardService, {
  OPortfolioValueLineChart,
} from "../../../services/DashboardService";
import dateFormat from "dateformat";
import { CurrencyEnum } from "../../../models/CurrencyEnum";
import UtilService from "../../../services/UtilService";
import { useTranslation } from "react-i18next";
import { ChartOptions } from "chart.js";

export const PortfolioValueLineChartComponent: React.FC<{
  loaded: any;
  onlyFirstTimeShowLoading: boolean;
  refreshTime?: number;
  currency: CurrencyEnum;
  onChangeData?: any;
  loadDataOn: boolean;
  dataIn?: any;
  onChangeInterval?: any;
  intervalIn: string;
  minHeight?:string;
}> = ({
  loaded,
  onlyFirstTimeShowLoading,
  refreshTime,
  currency,
  onChangeData,
  loadDataOn,
  dataIn,
  onChangeInterval,
  intervalIn,
    minHeight = "180px"
}) => {
  const { t } = useTranslation();
  let timeout: any = undefined;

  const [data, setData] = useState<any>({
    labels: [],
    datasets: [
      {
        lineTension: 0,
        label: "",
        yAxisID: "left",
        borderColor: "rgba(250, 65, 65, 0.8)",
        data: [],
        pointRadius: 0,
        fill: false,
      },
      {
        lineTension: 0,
        label: "",
        yAxisID: "right",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        data: [],
        pointRadius: 0,
        fill: true,
      },
    ],
  });

  useEffect(() => {
    let firstTimeLoaded = true;
    const dashboardService: DashboardService = new DashboardService();
    async function getData() {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) loaded(false);

      try {
        const _data = await dashboardService.getPortfolioValueLineChart(
          UtilService.transformInterval(intervalIn)
        );
        let chartData = data;

        chartData.datasets![0].label = t("Capital");
        chartData.datasets![1].label = t("Bitcoin");

        chartData.labels = _data.map((m: OPortfolioValueLineChart) => {
          const date = Date.parse(m.portfolioLineChartDate);
          return dateFormat(date, "dd.mm.yyyy");
        });

        chartData.datasets![0].data = _data.map((m: any) =>
          parseFloat(
            m["lcCapital" + UtilService.getCurrencyToKeyPart(currency)]
          ).toFixed(2)
        );
        chartData.datasets![1].data = _data.map((m: any) =>
          parseFloat(
            m["valueBtc" + UtilService.getCurrencyToKeyPart(currency)]
          ).toFixed(2)
        );

        setData(chartData);
        onChangeData(chartData);
      } catch (e) {
        console.error(e);
      }

      loaded(true);
      firstTimeLoaded = false;

      if (
        (timeout == null || timeout == undefined) &&
        refreshTime !== null &&
        refreshTime !== undefined &&
        refreshTime > 0
      ) {
        timeout = setTimeout(() => getData(), refreshTime);
      }
    }

    if (loadDataOn) {
      getData();
    } else {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) loaded(false);
      setData(dataIn);
      loaded(true);
    }
  }, [currency, t("Capital"), t("Bitcoin"), dataIn, intervalIn]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const options: any = {
    // Boolean - If we should show the scale at all
    showScale: true,
    // Boolean - Whether grid lines are shown across the chart
    scaleShowGridLines: false,
    // String - Colour of the grid lines
    scaleGridLineColor: "rgba(0,0,0,.05)",
    // Number - Width of the grid lines
    scaleGridLineWidth: 1,
    // Boolean - Whether to show horizontal lines (except X axis)
    scaleShowHorizontalLines: true,
    // Boolean - Whether to show vertical lines (except Y axis)
    scaleShowVerticalLines: true,
    // Number - Tension of the bezier curve between points
    lineTension: 0.3,
    // Number - Radius of each point dot in pixels
    pointRadius: 0,
    // Boolean - Whether to show a stroke for datasets
    datasetStroke: true,
    // Number - Pixel width of dataset stroke
    datasetStrokeWidth: 2,
    // Boolean - Whether to fill the dataset with a color
    datasetFill: true,
    legend: {
      display: true,
      position: "bottom",
    },
    // String - A leg,end template
    // Boolean - whether to maintain the starting aspect ratio or not when responsive, if set to false, will take up entire container
    maintainAspectRatio: false,
    // Boolean - whether to make the chart responsive to window resizing
    responsive: true,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label(tooltipItem: any, data: any) {
          return (
            data.datasets![tooltipItem.datasetIndex].label +
            ": " +
            data.datasets![tooltipItem.datasetIndex].data?.at(
              tooltipItem.index!
            ) +
            " " +
            currency
          );
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: (val: any, index: any) => {
              // Hide every 2nd tick label
              return index % 2 === 0 ? val : "";
            },
          },
          scaleLabel: {
            display: false,
            labelString: "Portfolio",
          },
          id: "left",
          stacked: false,
        },
        {
          scaleLabel: {
            display: false,
            labelString: "Bitcoin",
          },
          id: "right",
          position: "right",
          stacked: false,
          ticks: {
            display: false,
          },
        },
      ],
    },
  };

  return (
    <div>
      <div>
        <label style={{ marginRight: "10px" }}>{t("Interval")}</label>
        <select
          onChange={(event) => {
            onChangeInterval(event.target.value);
          }}
          value={intervalIn}
        >
          <option value="week">{t("week")}</option>
          <option value="month">{t("month")}</option>
          <option value="3month">3 {t("month")}</option>
          <option value="6month">6 {t("month")}</option>
          <option value="year">{t("year")}</option>
          <option value="full">{t("full")}</option>
        </select>
      </div>
      <div style={{minHeight:minHeight}}>
        <Line
          data={data}
          options={options}
          datasetKeyProvider={() => {
            return UtilService.datasetKeyProvider();
          }}
        />
      </div>
    </div>
  );
};
