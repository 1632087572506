import React, { useState } from "react";
import { CurrencyEnum } from "../models/CurrencyEnum";
import { LanguageEnum } from "../models/LanguageEnum";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

export const Header: React.FC<{
  onChangeCurrency: any;
}> = ({ onChangeCurrency }) => {
  let navigate = useNavigate();
  /*const [currency, setCurrency] = useState<CurrencyEnum>(
    CurrencyEnum.USDT === (localStorage.getItem("currency") || "$")
      ? CurrencyEnum.USDT
      : CurrencyEnum.EUR
  );*/

  const [currency, setCurrency] = useState<CurrencyEnum>(CurrencyEnum.USDT);
  const [language, setLanguage] = useState<LanguageEnum>(
    LanguageEnum.EN === (localStorage.getItem("i18nextLng") || "en")
      ? LanguageEnum.EN
      : LanguageEnum.SK
  );
  const langToSet = language.includes("sk") ? "sk" : language.toString();
  if (i18next.language !== langToSet) i18next.changeLanguage(langToSet);
  onChangeCurrency(currency);

  const { t } = useTranslation();

  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <a
              className="nav-link"
              data-toggle="dropdown"
              href="#"
              aria-expanded="false"
            >
              {language === LanguageEnum.SK ? t("Slovak") : ""}
              {language === LanguageEnum.EN ? t("English") : ""}
            </a>
            <div
              className="dropdown-menu dropdown-menu-md dropdown-menu-right"
              style={{ left: "inherit", right: 0 }}
            >
              <a
                onClick={(e) => {
                  const language = LanguageEnum.SK;
                  setLanguage(language);
                  i18next.changeLanguage("sk");
                }}
                className="dropdown-item"
              >
                {t("Slovak")}
              </a>
              <div className="dropdown-divider" />
              <a
                onClick={(e) => {
                  const language = LanguageEnum.EN;
                  setLanguage(language);
                  i18next.changeLanguage("en");
                }}
                className="dropdown-item"
              >
                {t("English")}
              </a>
            </div>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link"
              data-toggle="dropdown"
              href="#"
              aria-expanded="false"
            >
              {currency === CurrencyEnum.USDT ? (
                <i className="fas fa-dollar-sign" />
              ) : (
                ""
              )}
              {/*currency === CurrencyEnum.EUR ? (
                <i className="fas fa-euro-sign" />
              ) : (
                ""
              )*/}
            </a>
            <div
              className="dropdown-menu dropdown-menu-md dropdown-menu-right"
              style={{ left: "inherit", right: 0 }}
            >
              <a
                onClick={(e) => {
                  const currency = CurrencyEnum.USDT;
                  setCurrency(currency);
                  localStorage.setItem("currency", currency);
                  onChangeCurrency(currency);
                }}
                className="dropdown-item"
              >
                <i className="fas fa-dollar-sign mr-2" /> Dollar
              </a>
              {/*<div className="dropdown-divider" />
              <a
                onClick={(e) => {
                  const currency = CurrencyEnum.EUR;
                  setCurrency(currency);
                  localStorage.setItem("currency", currency);
                  onChangeCurrency(currency);
                }}
                className="dropdown-item"
              >
                <i className="fas fa-euro-sign mr-2" /> Euro
              </a>*/}
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="control-sidebar"
              data-controlsidebar-slide="true"
              onClick={(e) => navigate("/logout")}
              role="button"
            >
              <i className="fas fa-sign-out-alt" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};
